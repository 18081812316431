<template>
  <div :class="{ pinned: is_pinned }">
    <div
      v-for="(message, index) in internal_messages"
      :key="index"
      :class="
        'alert alert-' + (message.level == 'error' ? 'danger' : message.level == 'warn' ? 'warning' : message.level)
      "
    >
      <div v-if="!unclosable" style="cursor: pointer; float: right; margin-left: 5px" @click="deleteMess(index)">X</div>
      <font-awesome-icon
        v-if="message.style == 'bold'"
        icon="exclamation-circle"
        style="margin: auto; margin-right: 10px; font-size: 1.5rem"
      ></font-awesome-icon>
      <div v-if="message.title">
        <h4 v-if="message.title" style="margin-bottom: 0.7em; font-weight: bold">
          <font-awesome-icon
            icon="exclamation-circle"
            style="margin: auto; margin-right: 10px; font-size: 1.5rem"
          ></font-awesome-icon>
          {{ message.title }}
        </h4>
      </div>
      <span :class="message.style" v-html="replace_placeholders(message.text)"></span> <slot name="helper"></slot>
      <p v-if="message.call_for_action" style="text-align: center" @click="emit('act', message.call_for_action.action)">
        <button class="btn btn-sm btn-primary">{{ message.call_for_action.text }}</button>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { ApiMessage } from "@/ems/WSClient";
const checkIcon =
  '<svg data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="far fa-check-square svg-inline--fa fa-check fa-w-16" style="cursor: pointer; margin-right: 0.5em;"><path data-v-72f559a6="" fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" class=""></path></svg>';
const cogIcon =
  '<svg data-v-bd14c378="" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-cog fa-w-16 "><path data-v-bd14c378="" fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" class=""></path></svg>';

const downloadIcon =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-download"><path fill="currentColor" d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z" class=""></path></svg>';

const props = defineProps<{
  is_pinned?: boolean;
  messages?: ApiMessage[];
  unclosable?: boolean;
}>();

const emit = defineEmits<{
  (e: "act", value: string): void;
  (e: "unblur"): void;
}>();

const internal_messages = ref<ApiMessage[]>(props.messages ? props.messages : []);

const deleteMess = (index: number): boolean => {
  emit("unblur");
  return !!internal_messages.value.splice(index, 1);
};

const replace_placeholders = (txt: string): string => {
  const mapping = {
    "<check>": checkIcon,
    "\n": "<br>",
    "<cog>": cogIcon,
    "<download>": downloadIcon,
    ' href="/': ' href="' + import.meta.env.BASE_URL,
  };
  for (const [key, value] of Object.entries(mapping)) {
    txt = txt.replaceAll(key, value);
  }
  return txt;
};

watch(
  () => props.messages,
  (newArr) => {
    internal_messages.value = newArr ? newArr : [];
  }
);
</script>

<style>
.bold {
  font-weight: bold;
}

.pinned {
  float: right;
  width: calc(100% - 8rem);
}

.pinned .alert {
  margin-bottom: 0 !important;
}
</style>
