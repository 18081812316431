<template>
  <div>
    <div><h3>Versions</h3></div>
    <div class="studyblock">
      <div class="header studycontent">
        <h3>Version {{ selected_version?.Version }}</h3>
        <SmallTopLoader :loading="loading" />
        <Messages :messages="messages" />
        <p class="text-info">Released: {{ selected_version?.VersionDate }}</p>
        <Messages v-if="content.length == 0" :messages="emptyMessage" />
        <p v-for="log in content" :key="log.UpdateDate">
          <div v-html="log.UpdateText "></div>
        </p>
      </div>
    </div>
    <div>
      <h3>Other versions</h3>
      <ul>
        <li class="logLnk" :to="{ name: 'version' }" v-for="version in versions" :key="version.Version">
          <router-link :to="{ name: 'version', params: { version_id: version.Version } }">
            Version {{ version.Version }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed,watch } from "vue";
import { useRoute } from "vue-router";
import { getFirstParam } from "@/ems/utils";
import Messages from "@/components/Messages.vue";
import SmallTopLoader from "@/components/SmallTopLoader.vue";
import { ApiMessage, WSClient } from "@/ems/WSClient";

interface VersionLog{
  UpdateDate: string;
  UpdateText: string;
  UpdateMajor:string
}
interface Version{
  VersionDate: string;
  Version: string;
  Current: number;
}
const route = useRoute();

interface Props {}

const props = defineProps<Props>();

const loading = ref(false);
const messages = ref<ApiMessage[]>([]);
const ws_client = new WSClient(loading, messages);
const versions = ref<Version[]>([]);
const content = ref<VersionLog[]>([]);
const emptyMessage = [{ level: "info", text: "No update available" }];
onMounted(async () => {
  await getVersions();
  if (selected_version_id.value) {
    await getLog(selected_version_id.value);
  }
});
watch(route, () => {
  if (selected_version_id.value) {
    getLog(selected_version_id.value);
  }
});
const selected_version_id = computed(() => {
  return getFirstParam(route.params.version_id) ?? current_version.value?.Version;
});
const selected_version = computed(() => {
  return versions.value.filter((v) => v.Version == selected_version_id.value)[0];
});
const current_version = computed(() => {
  const vs = versions.value.filter((v) => v.Current == 1);
  return vs.length > 0 ? vs[0] : null;
});
const getVersions = async () => {
  const resp = await ws_client.queryWs<{ versions: Version[] }>("GET", "env_versionList");
  versions.value = resp.versions;
};

const getLog = async (version_id: string) => {
  const data = await ws_client.queryWs<{ logs: VersionLog[] }>("GET", "env_versionLog", {
    version: version_id,
  });
  content.value = data.logs;
};
</script>

<style>
.logLnk {
  margin-right: 3em;
}
.text-info {
  color: #31708f;
  font-size: 14px;
  margin: 10px 10px 5px 0px;
}
</style>
