import loginGard from "./login_gard";
import { RouteRecordRaw } from "vue-router";

const bo_routes: Array<RouteRecordRaw> = [
  {
    path: "/bo",

    component: () => import("../views/bo/BoHome.vue"),
    beforeEnter: loginGard,
    children: [
      {
        path: "",
        name: "bo_home",
        component: () => import("../views/bo/BoUsers.vue"),
      },
      {
        name: "user_details",
        path: "user/:user_id",
        component: () => import("../views/bo/BoUserDetail.vue"),
      },
      {
        path: "series/list",
        component: () => import("../views/bo/BoSeries.vue"),
      },
      {
        name: "serie_details",
        path: "series/:series_id",
        component: () => import("../views/bo/BoSerieDetail.vue"),
      },
      {
        name: "logs",
        path: "logs",
        component: () => import("../views/bo/BoLogs.vue"),
      },
      {
        name: "branch",
        path: "branch",
        component: () => import("../views/bo/BoBranch.vue"),
      },
      {
        name: "BoOrganizationsHome",
        path: "organizations",
        component: () => import("../views/bo/BoOrganizations.vue"),
      },
      {
        name: "BoOrganizationsNew",
        path: "organizations/new",
        component: () => import("../views/bo/BoOrganizationsNew.vue"),
      },
      {
        name: "BoOrganizationsEdit",
        path: "organizations/:organization_id",
        component: () => import("../views/bo/BoOrganizationsNew.vue"),
      },
      {
        name: "BoOrganizationsSubscriptions",
        path: "organizations/:organization_id/subscriptions",
        component: () => import("../views/bo/BoOrganizationsSubscriptions.vue"),
      },
      {
        name: "BoSystem",
        path: "system",
        component: () => import("../views/bo/BoSystem.vue"),
      },
      {
        name: "BoSchema",
        path: "schema",
        component: () => import("../views/bo/BoSchema.vue"),
      },
    ],
    meta: {
      requiresAdmin: true,
    },
  },
];

export default bo_routes;
