import { defineStore } from "pinia";
import { WSClient } from "@/ems/WSClient";
import { Serie, Cat, Variable, Filter, FilterGroupLists } from "@/types/serie";
import { getUserPermission, Role } from "@/ems/perms";
import { handleCurrentSerieExtra } from "@/ems/format_extra";
import { DescriptiveSummary } from "@/views/series/descriptive/types";
export const serieContextStore = defineStore("serie_context", {
  state: () => ({
    serie_user_permissions: {} as Record<string, boolean>,
    serie: {} as Serie,
    serie_roles: [] as Role[],
    descriptive: {} as DescriptiveSummary,
    categories: [] as Cat[],
    variables: undefined as Variable[] | undefined,
    filters: [] as Filter[],
    filter_history: [] as Filter[],
    filter_groups: {} as FilterGroupLists,
    new_patient_created: false as boolean,
  }),
  getters: {},
  actions: {
    async load_user_permissions(client: WSClient, preferences: string[]) {
      this.serie_user_permissions = Object.assign(
        this.serie_user_permissions,
        await getUserPermission(client, preferences, this.serie.SerieId)
      );
    },
    async load_serie(client: WSClient, serie_id: string | number, force_query = false): Promise<Serie | null> {
      if (!force_query && this.serie.SerieId && serie_id == this.serie.SerieId) {
        return Promise.resolve(this.serie);
      }
      console.log("Openning serie " + serie_id);
      const result = await client.queryWs<{ serie: Serie; roles: Role[]; descriptive_summary: DescriptiveSummary }>(
        "GET",
        "serie_serie",
        {
          serie_id: serie_id,
          patient_count: true,
          descriptive_summary: false,
          with_plan: true,
          roles: true,
          withProdTooltip: true,
        }
      );

      if (!result.serie) return null;

      result.serie = handleCurrentSerieExtra(result.serie);
      this.descriptive = result.descriptive_summary;
      this.serie = result.serie;
      this.serie_roles = result.roles;
      return this.serie;
    },
    async load_filter(client: WSClient): Promise<void> {
      const resp = await client.queryWs<{
        filters: Filter[];
        filter_history: Filter[];
        filter_groups: FilterGroupLists;
      }>("GET", "filter_session", {
        serie_id: this.serie.SerieId,
        filter_history: true,
        filter_groups: true,
      });
      this.filters = resp.filters;
      this.filter_history = resp.filter_history;
      this.filter_groups = resp.filter_groups;
    },
    async load_cats(client: WSClient): Promise<void> {
      const resp = await client.queryWs<{ variables_categories: Cat[] }>("GET", "variable_varCatOfSerie", {
        serie_id: this.serie.serieId,
      });
      this.categories = resp.variables_categories;
    },
    async load_vars(client: WSClient): Promise<Variable[]> {
      if (this.variables != undefined) return Promise.resolve(this.variables);
      const resp = await client.queryWs<{ variables: Variable[] }>("GET", "variable_variable", {
        var_count: this.serie.NumberOfPatients < 5000,
        category: true,
        with_not_analysed: true,
        with_picture_exists: true,
        all_data: true,
        sid: this.serie.SerieId,
      });
      this.variables = resp.variables;
      return Promise.resolve(this.variables);
    },
  },
});
