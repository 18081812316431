import defaultRouter from "@/router";
import { store } from "../store";
/**
 * Web-service client
 * @param {*} parent Parent object that will holds results, messages and loading indicator.
 */

export default {
  create: function (parent, router) {
    const rootApi = import.meta.env.VITE_WS_URL || "";

    const query = function (method, url, data, handleLoading = true) {
      if (handleLoading) this.onLoading(true);
      let headers = { "Content-Type": "application/json" },
        jwt = sessionStorage && sessionStorage.getItem("jwt");
      if (jwt) headers.Authentication = "Bearer " + jwt;
      return fetch(url, {
        method: method,
        headers: new Headers(headers),
        body: data ? JSON.stringify(data) : null,
      })
        .then((response) => {
          return response.text().then((text) => {
            if (handleLoading) this.onLoading(false);
            let respData = null;
            const contentType = response.headers.get("Content-type");
            if ((contentType && contentType.includes("application/json")) || text.charAt(0) === "{" || !text) {
              respData = text ? JSON.parse(text) : {};
              respData.status = response.status;
            }
            if (respData && respData.messages) {
              this.onMessages(respData.messages);
            }
            handle_error_codes(response.status, router || defaultRouter, respData?.messages);

            if (respData && respData.jwt && sessionStorage) sessionStorage.setItem("jwt", respData.jwt);

            return new Promise(function (resolve, reject) {
              if (response.ok) {
                resolve(respData || text);
              } else {
                if (!respData) {
                  this.onMessages([{ level: "error", text: text }]);
                }
                reject(respData || text);
              }
            });
          });
        })
        .catch((error) => {
          if (handleLoading) this.onLoading(false);
          if (typeof error === "object") {
            if (error.messages) {
              this.onMessages(error.messages);
            } else {
              console.error("Error " + JSON.stringify(error.messages));
            }
          } else {
            this.onMessages([{ level: "error", text: "Error " + error }]);
          }
          handle_error_codes(error.status, router || defaultRouter, error?.messages);
          return new Promise(function (resolve, reject) {
            reject(error);
          });
        });
    };

    const handle_error_codes = (code, router, messages) => {
      const cr = router?.currentRoute?.value;
      if (router && cr?.name != "login") {
        if (code == 401 || code == 409) {
          console.log("Redirect to login because of code", code);
          goto_login(router, code);
        } else if (code == 403) {
          console.log("Redirect to not allowed because of code", code);
          router.push({
            name: get_not_allowed_name(router.currentRoute.value),
            params: {
              messages: messages,
              serie_id: router.currentRoute.value?.params?.serie_id,
            },
          });
        }
      }
    };

    const goto_login = (router, error) => {
      let route = router.currentRoute.path ?? router.currentRoute.value.path;
      let query = { error };
      if (route && route !== "/login") query.redir_on_success = route;
      router.push({
        path: "/login",
        query,
      });
    };

    const get_not_allowed_name = (current_route) => {
      if (current_route.path?.toLowerCase()?.includes("organization")) return "OrganizationNotAllowed";

      if (current_route.params.series_id) return "NotAllowedInSerie";

      return "NotAllowed";
    };

    const getWs2Url = function (service, urlParams) {
      return rootApi + "/ws2.php?service=" + service + toQueryString(urlParams);
    };

    const toQueryString = function (arr) {
      if (!arr) return "";
      const params = [];
      Object.keys(arr)
        .filter((key) => typeof arr[key] != "undefined")
        ?.forEach((key) => {
          const k = `${key}=`,
            v = arr[key];
          if (v === null) return;
          if (Array.isArray(v)) {
            v.forEach((v2) => {
              params.push(k + encodeURIComponent(v2.toString()));
            });
          } else {
            params.push(k + encodeURIComponent(v.toString()));
          }
        });
      return "&" + params.join("&");
    };

    const query_ws2 = function (service, method, urlParams, body, handleLoading = true) {
      const url = getWs2Url(service, urlParams);
      return this.query(method, url, body, handleLoading);
    };

    const getAllDataFromWs = function (service, method, urlParams, body, handleLoading = true) {
      return this.query(method, getWs2Url(service, urlParams), body, handleLoading).then((res) => {
        if (res && res.status == 200)
          for (const [key, value] of Object.entries(res)) {
            parent[key] = value;
          }
        return res;
      });
    };

    const onMessages = function (messages) {
      if (!parent || !("messages" in parent)) return;
      parent.messages = messages;
    };

    const onLoading = function (isLoading) {
      if (!parent || !("loading" in parent) || parent["override_loading"]) return;
      parent.loading = isLoading;
    };

    const getData = function (method, url, data, target) {
      return this.query(method, url, data).then((res) => {
        parent[target] = res;
      });
    };

    const getAllData = function (method, url, data) {
      return this.query(method, url, data).then((res) => {
        if (res)
          for (const [key, value] of Object.entries(res)) {
            parent[key] = value;
          }
        return res;
      });
    };

    return {
      parent,
      query,
      getData,
      getAllData,
      onMessages,
      onLoading,
      query_ws2,
      getAllDataFromWs,
      getWs2Url,
    };
  },
};
