<template>
  <div class="nav-links">
    <div
      v-for="(link, idx) in foundNav"
      :key="idx"
      class="nav"
      :class="idx == selected ? 'nav-selected' : ''"
      @click="goToPage(idx)"
    >
      <font-awesome-icon v-if="link.icon" :icon="link.icon" class=""> </font-awesome-icon>
      <span style="margin-left: 1.5rem; font-size: 1.6rem">{{ link.name }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ApiMessage, WSClient } from "@/ems/WSClient";
import { serieContextStore } from "@/store/serie_context";
import { onMounted, ref, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import Fuse from "fuse.js";
const route = useRoute();
interface Props {
  goToSelected: boolean;
  search: string;
  selected: number | undefined;
}
const serieContext = serieContextStore();
const nav = [
  {
    name: "Settings",
    icon: "arrow-right",
    route: {
      name: "general_info_settings",
    },
  },
  {
    name: "Share serie",
    icon: "arrow-right",
    route: {
      name: "sharing_new",
    },
  },
  {
    name: `Add a new ${serieContext.serie.StudyUnit}`,
    icon: "arrow-right",
    route: {
      name: "NewPatient",
    },
  },
  { name: "Add a variable", icon: "arrow-right", route: { name: "variable", params: { var_id: "new" } } },
  { name: "Add a category", icon: "arrow-right", route: { name: "category", params: { cat_id: "new" } } },
  { name: "Descriptive", icon: "arrow-right", route: { name: "descriptive_summary" } },
  { name: "Test variables", icon: "arrow-right", route: { name: "testvars" } },
  { name: "Multivariate analysis", icon: "arrow-right", route: { name: "multivariate_list" } },

  { name: "Compare groups", icon: "arrow-right", route: { name: "comparegroups" } },
];
const fuseOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 0,
  // location: 0,
  // threshold: 0.6,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["name"],
};
const fuse = new Fuse(nav, fuseOptions);

const loading = ref(false);
const props = defineProps<Props>();
const messages = ref<ApiMessage[]>([]);
const local_client = new WSClient(loading, messages);
const router = useRouter();
const emit = defineEmits(["close", "nav-found"]);

watch(
  () => props.goToSelected,
  (newval) => {
    if (newval) {
      if (props.selected != undefined) {
        goToPage(props.selected);
      }
    }
  }
);

const goToPage = (nav: number) => {
  if (nav < 0) {
    return;
  }
  if (nav >= foundNav.value.length) {
    return;
  }
  router.push(foundNav.value[nav].route);
  emit("close");
};
const foundNav = computed(() => {
  const found = fuse.search(props.search).map((x) => x.item);
  emit("nav-found", found.length);
  return found;
});
onMounted(() => {
  emit("nav-found", nav.length);
});
</script>

<style lang="css" scoped>
.nav-links {
  text-align: left;
}
.nav {
  padding: 1.5rem 10rem 1.5rem 5rem;
  width: 100%;
  cursor: pointer;
}
.nav:hover {
  background-color: var(--grey-95);
}
.nav-selected {
  background-color: var(--grey-95);
}
</style>
