<template>
  <div class="central_container">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style>
/**Central container */
.central_container {
  position: relative;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  min-height: 300px;
}

@media (min-width: 768px) {
  .central_container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .central_container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .central_container {
    width: 1170px;
  }
}
</style>
