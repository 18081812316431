<template>
  <button v-if="showButton" @click="scrollToTop" class="back-to-top">Back to Top</button>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

const showButton = ref(false);
const scrolledEnough = ref(false);
const cursorCloseToBottom = ref(false);

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const checkScrollPosition = () => {
  scrolledEnough.value = window.scrollY > 300;
  updateButtonVisibility();
};

const checkCursorPosition = (event: MouseEvent) => {
  const cursorY = event.clientY;
  const windowHeight = window.innerHeight;
  const distanceFromBottom = windowHeight - cursorY;
  cursorCloseToBottom.value = distanceFromBottom < 120;
  updateButtonVisibility();
};

const updateButtonVisibility = () => {
  showButton.value = scrolledEnough.value && cursorCloseToBottom.value;
};

onMounted(() => {
  window.addEventListener("scroll", checkScrollPosition);
  window.addEventListener("mousemove", checkCursorPosition);
});

onUnmounted(() => {
  window.removeEventListener("scroll", checkScrollPosition);
  window.removeEventListener("mousemove", checkCursorPosition);
});
</script>
<style scoped>
.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: var(--green-medium-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  font-size: 16px;
}

.back-to-top:hover {
  background-color: var(--green-medium-color);
  box-shadow: 0 6px 6px var(--grey-64);
  color: white;
}

span {
  margin-left: 1rem;
}
</style>
