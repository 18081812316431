<template>
  <select id="countryCode" class="form-control" name="country" @change="change" autocomplete="country">
    <option value="">-- Choose a country --</option>
    <option v-for="(label, code) in countries" :key="code" :value="code" :selected="code == modelValue">
      {{ label }}
    </option>
  </select>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import client from "@/ems/ws_client";
import { ApiMessage, WSClient } from "@/ems/WSClient";
interface Props {
  modelValue: string | null | undefined;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();
const loading = ref(false);
const messages = ref<ApiMessage[]>([]);
const local_client = new WSClient(loading, messages);

const countries = ref<Record<string, string>>({});

onMounted(async () => {
  const resp = await local_client.queryWs<{ countries: Record<string, string> }>("GET", "user_country", {
    all: true,
  });
  countries.value = resp.countries;
});

const change = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  emit("update:modelValue", target.value);
};
</script>
