<template>
  <Teleport to="body">
    <div id="loadingPopup" class="popup" :style="cloakStyle">
      <div class="body popup-body" :style="windowStyle" v-on-click-outside="onClickOutsideHandler">
        <font-awesome-icon
          v-if="!noclose"
          class="closeBt"
          style="cursor: pointer"
          @click.prevent="close"
          :icon="['fa', 'circle-xmark']"
        />
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, CSSProperties } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { useMagicKeys, whenever } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    max_width?: string;
    max_height?: string;
    min_width?: string;
    min_height?: string;
    width?: string;
    height?: string;
    overflow?: string;
    align?: string;
    top?: string;
    shown?: boolean;
    noclose?: boolean;
    window_style?: CSSProperties;
  }>(),
  {
    shown: true,
  }
);

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};

const onClickOutsideHandler: [(evt: any) => void, {}] = [
  (evt: any) => {
    close();
  },
  { ignore: ["[class*=vc]", "[class*=popup-body]"] },
];

const keys = useMagicKeys();

whenever(keys.Escape, () => {
  close();
});
const cloakStyle = computed(() => {
  return props.shown ? {} : { display: "none", opacity: 1 };
});

const windowStyle = computed(() => {
  let st = {
    "text-align": props.align ?? "center",
    "max-width": props.max_width,
    "min-width": props.min_width,
    "min-height": props.min_height,
    max_height: props.max_height,
    top: props.top,
    width: props.width,
    height: props.height,
    ...props.window_style,
  } as any;

  if (props.overflow) st.overflow = props.overflow;
  Object.keys(st).forEach((key) => (st[key] === undefined ? delete st[key] : {}));
  return st;
});
</script>

<style scoped>
.popup {
  position: fixed;
  top: -50px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  text-align: center;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}

.popup > div {
  position: relative;
  overflow: auto;
  max-height: 700px;
  margin: 0 auto;
  border: 1px solid white;
  background-color: white;
  padding: 3rem 7rem 4rem;
  border-radius: 8px;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.24);
  pointer-events: initial;
}

.body-popup {
  display: flex;
  justify-content: center;
  min-width: 420px;
}

.popup .closeBt {
  position: absolute;
  right: 2rem;
  top: 2rem;
  font-size: 1.3em;
  font-weight: normal;
  color: var(--grey-46);
  cursor: pointer;
}
</style>
