<template>
  <div class="patients" v-if="patients.length">
    <div
      v-for="(patient, idx) in patients"
      :key="patient.PatientId"
      class="patient-found"
      :class="idx == selected ? 'patient-selected' : ''"
      @click="goToPatient(idx)"
    >
      <span class="tooltiped">
        <font-awesome-icon icon="info-circle" />
        <span v-if="tooltip(patient)" class="tooltiptext">{{ tooltip(patient) }}</span>
      </span>
      <router-link
        :to="{
          name: 'PatientForm',
          params: {
            patient_id: patient.PatientId,
            series_id: serieContext.serie.SerieId,
          },
        }"
      >
        <span v-if="patient.CustomId">{{ patient.CustomId }}</span>
        <span v-else-if="patient.Initials">{{ patient.Initials }}</span>
        <font-awesome-icon v-else icon="pen" class=""> </font-awesome-icon>
      </router-link>
      <font-awesome-icon
        v-if="patient.Gender && !patient.CustomId"
        :icon="patient.Gender == 'M' ? 'mars' : 'venus'"
        :class="['gender', patient.Gender]"
      >
      </font-awesome-icon>
      <span v-if="!patient.CustomId">{{ age(patient) }}</span>
      <span class="birth-date" v-if="patient.BirthDate && !patient.CustomId">({{ patient.BirthDate }})</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ApiMessage, WSClient } from "@/ems/WSClient";
import { serieContextStore } from "@/store/serie_context";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
interface Props {
  goToSelected: boolean;
  search: string;
  selected: number | undefined;
}
interface Patient {
  PatientId: string;
  Initials: string;
  Gender: string;
  BirthDate: string;
  InclusionDate: string;
  LastConsultation: string;
  DeathDate: string;
  Age: number;
  CustomId?: string;
}
const loading = ref(false);
const props = defineProps<Props>();
const messages = ref<ApiMessage[]>([]);
const local_client = new WSClient(loading, messages);
const patients = ref<Patient[]>([]);
const router = useRouter();
const serieContext = serieContextStore();
const emit = defineEmits(["close", "patients-found"]);
const search = (searchString: string) => {
  if (searchString?.length < 3) {
    patients.value = [];
    return;
  }
  if (serieContext.serie.SerieId == undefined) return;
  local_client
    .queryWs<{ patients: Patient[] }>("GET", "serie_search", {
      sid: serieContext.serie.SerieId,
      q: searchString,
    })
    .then((resp) => {
      patients.value = resp.patients;
      console.log("Patients found");

      emit("patients-found", patients.value.length);
    });
};
watch(
  () => props.search,
  (newVal) => {
    search(newVal);
  }
);
watch(
  () => props.goToSelected,
  (newval) => {
    if (newval) {
      if (props.selected != undefined) {
        goToPatient(props.selected);
      }
    }
  }
);
const age = function (patient: Patient) {
  return patient.Age ? patient.Age + "y.o. " : "";
};
const tooltip = function (patient: Patient) {
  let res = [];
  if (patient.BirthDate) res.push("Birth Date: " + patient.BirthDate);
  if (patient.InclusionDate) res.push("Inclusion Date: " + patient.InclusionDate);
  if (patient.LastConsultation) res.push("Last Consultation: " + patient.LastConsultation);
  if (patient.DeathDate) res.push("Death Date: " + patient.DeathDate);
  return res ? res.join("\n") : "";
};
const goToPatient = (patientId: number) => {
  if (patientId == undefined) return;
  if (patientId >= patients.value.length) return;
  if (serieContext.serie.SerieId == undefined) return;
  router.push({
    name: "PatientForm",
    params: {
      patient_id: patients.value[patientId].PatientId,
      series_id: serieContext.serie.SerieId,
    },
  });
  emit("close");
};
</script>

<style lang="css" scoped>
.patients {
  text-align: left;
}
.patient-found {
  padding: 1.5rem 10rem 1.5rem 5rem;
  width: 100%;
  cursor: pointer;
}
.patient-found:hover {
  background-color: var(--grey-95);
}
.patient-selected {
  background-color: var(--grey-95);
}

.patient-found .tooltiped {
  color: black;
  margin-right: 0.5em;
}

.patient-found a {
  margin-right: 0.5em;
  font-weight: bold;
}

.patient-found .gender {
  margin-right: 0.2em;
}
</style>
