<template>
  <Teleport to="body">
    <div id="loadingPopup" class="popup">
      <div class="body popup-body" v-on-click-outside="onClickOutsideHandler" style="width: 50%; top: 10rem">
        <div>
          <div class="search-input-container">
            <font-awesome-icon icon="search" style="margin-right: 0.5rem; font-size: 2rem" />
            <input
              type="text"
              ref="searchinput"
              class="search-input"
              v-model="searchValue"
              @change=""
              placeholder="Type a command or search a patient ..."
            />
          </div>
          <div style="max-height: 70vh; overflow-y: auto" class="scrollable">
            <Patients
              :search="searchValue"
              :selected="selectedPanel == 'patients' ? selectedOption : undefined"
              :goToSelected="selectedPanel == 'patients' ? goToSelected : false"
              @close="emit('close')"
              @patients-found="onPatientsFound"
            />
            <Navigation
              :search="searchValue"
              :selected="selectedPanel == 'nav' ? selectedOption : undefined"
              :goToSelected="selectedPanel == 'nav' ? goToSelected : false"
              @close="emit('close')"
              @nav-found="navCount = $event"
            ></Navigation>
          </div>
          <div class="footer">
            <div style="display: inline-flex; justify-content: space-around;between; width: 100%">
              <div style="display: inline-flex; gap: 0.5rem">
                <!-- <kbd class="kbd" style="">⌘</kbd>
                <kbd class="kbd">⌥</kbd> -->
                <kbd class="kbd" style="padding-left: 0.6rem"> Esc </kbd>
                <span style="margin: auto">Close Menu</span>
              </div>
              <div style="display: inline-flex; gap: 0.5rem">
                <kbd class="kbd" style="">⇧</kbd>
                <span style="margin: auto">Previous</span>
              </div>
              <div style="display: inline-flex; gap: 0.5rem">
                <kbd class="kbd" style="">⇩</kbd>
                <span style="margin: auto">Next</span>
              </div>
              <div style="display: inline-flex; gap: 0.5rem">
                <kbd class="kbd" style="">↲</kbd>
                <span style="margin: auto">Select</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { useMagicKeys, whenever } from "@vueuse/core";
import { onKeyStroke } from "@vueuse/core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Patients from "./searchbox/Patients.vue";
import Navigation from "./searchbox/Navigation.vue";

const emit = defineEmits(["close"]);
const searchValue = ref("");
const selectedOption = ref();
const selectedPanel = ref("nav");
const patientCount = ref(0);
const navCount = ref(0);
const goToSelected = ref(false);
const keys = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.ctrlKey && ["k", "K"].includes(e.key)) {
      e.preventDefault();
    }
    if (e.key == "ArrowDown" || e.key == "ArrowUp") {
      e.preventDefault();
    }
  },
});
const searchinput = ref<null | { focus: () => null }>(null);

whenever(keys.Escape, () => {
  emit("close");
});
onKeyStroke("ArrowDown", () => {
  let currentCount = 0;
  if (selectedPanel.value == "patients") {
    currentCount = patientCount.value;
  } else if (selectedPanel.value == "nav") {
    currentCount = navCount.value;
  }
  if (selectedOption.value == undefined) {
    selectedOption.value = 0;
  } else if (selectedOption.value == currentCount - 1) {
    selectedOption.value = 0;
    if (selectedPanel.value == "patients" && navCount.value > 0) {
      selectedPanel.value = "nav";
    } else if (patientCount.value > 0) {
      selectedPanel.value = "patients";
    }
  } else {
    selectedOption.value += 1;
  }
});
onKeyStroke("ArrowUp", () => {
  if (selectedOption.value == undefined) {
    if (navCount.value > 0) {
      selectedOption.value = navCount.value - 1;
      selectedPanel.value = "nav";
    } else if (patientCount.value > 0) {
      selectedOption.value = patientCount.value - 1;
      selectedPanel.value = "patients";
    }
  } else if (selectedOption.value == 0) {
    if (selectedPanel.value == "nav" && patientCount.value > 0) {
      selectedPanel.value = "patients";
      selectedOption.value = patientCount.value - 1;
    } else if (selectedPanel.value == "patients" && navCount.value > 0) {
      selectedPanel.value = "nav";
      selectedOption.value = navCount.value - 1;
    } else if (selectedPanel.value == "nav") {
      selectedOption.value = navCount.value - 1;
    } else if (selectedPanel.value == "patients") {
      selectedOption.value = patientCount.value - 1;
    }
  } else {
    selectedOption.value -= 1;
  }
});

whenever(keys.Enter, () => {
  if (selectedOption.value == undefined) {
    selectedOption.value = 0;
  }
  goToSelected.value = true;
});

const onClickOutsideHandler: [(evt: any) => void, {}] = [
  (evt: any) => {
    emit("close");
  },
  { ignore: ["[class*=vc]", "[class*=popup-body]"] },
];
watch(searchValue, () => {
  selectedOption.value = undefined;
});
const onPatientsFound = (c: number) => {
  patientCount.value = c;
  if (c > 0) {
    selectedPanel.value = "patients";
  } else {
    selectedPanel.value = "nav";
  }
};
onMounted(() => {
  searchinput.value?.focus();
});
</script>

<style lang="css" scoped>
.search-bar {
  width: 30rem;
  height: 65%;
  margin: auto;
  border-style: solid;
  border-radius: 0.5rem;
  border-width: 1px;
  padding: 0.5rem;
  padding-right: 0.3rem;
  padding-left: 2rem;
  border-color: rgb(54, 145, 127);
  background-color: rgb(0, 168, 134);
  color: var(--green-light-color);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  transition-duration: 0.2s;
}
.search-bar:hover {
  background-color: rgb(30, 180, 150);
  color: white;
}
kbd {
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 255, 255);
  font-size: 0.85em;
  line-height: 0.85em;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: 0.6rem 0.2rem;
  white-space: nowrap;
}

.popup {
  position: fixed;
  top: -50px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  text-align: center;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
}

.popup > div {
  position: relative;
  margin: 0 auto;
  border: 1px solid white;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.24);
  pointer-events: initial;
  transition: all 0.3s ease;
}

.body-popup {
  display: flex;
  justify-content: center;
  min-width: 420px;
  transition: all 0.3s ease;
}
.search-input-container {
  padding-left: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid var(--grey-95);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.search-input {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  line-height: 3rem;
  font-size: 2rem;
  border: none;
}
.search-input:focus {
  border: none;
  outline: none;
}
.footer {
  border-top: 1px solid var(--grey-95);
  padding-top: 1rem;
  margin-bottom: 1rem;
}
kbd {
  border-radius: 0.5rem;
  border: 1px solid rgb(26, 26, 26);
  font-size: 0.85em;
  line-height: 0.85em;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: 0.6rem 0.4rem;
  white-space: nowrap;
}
.scrollable::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
  height: 50%;
}

.scrollable::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(157, 156, 156, 0.452); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
</style>
