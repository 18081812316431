import mixpanel from "@/ems/mixpanel";
import { getCurrentInstance } from "vue";
import { useStore } from "vuex";

export const useMixPanelEvent = () => {
  const vueInstance = getCurrentInstance();
  const store = useStore();
  const trackEvent = async (eventName: string, properties?: Record<string, any>) => {
    mixpanel.getMixpanel(vueInstance, store).then((mpEvent) => mpEvent && mpEvent.track(eventName, properties));
  };

  return {
    trackEvent,
  };
};
