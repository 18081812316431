import { createRouter, createWebHistory } from "vue-router";
import Version from "@/views/Version.vue";

import NotFound from "@/views/NotFound.vue";
import loginGard from "./login_gard";
import series_routes from "./series_routes";
import bo_routes from "./bo_routes";
import user_routes from "./user_routes";
import organization_routes from "./organization_routes";

const routes = [
  {
    path: "/",
    name: "root",
    beforeEnter: loginGard,
    meta: {
      requiresAuth: true,
    },
  },
  ...series_routes,
  ...bo_routes,
  ...user_routes,
  ...organization_routes,
  {
    path: "/samplesize/pmcf/",
    name: "samplesize_main",
    component: () => import("../views/samplesize/DmSampleSize.vue"),
  },
  {
    path: "/import/:datafile_id",
    alias: [
      "/import/:datafile_id/pii",
      "/import/:datafile_id/type",
      "/import/:datafile_id/wrong",
      "/import/:datafile_id/title",
    ],
    component: () => import("../views/import/ImportWizard.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: loginGard,
  },
  {
    path: "/version/:version_id?",
    name: "version",
    component: Version,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/not_allowed",
    name: "NotAllowed",
    component: () => import("../views/series/patient/NotAllowedPatient.vue"),
  },
];

import qs from "qs";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
});

console.log("Router base url", import.meta.env.BASE_URL);

router.php_app_url = function (target) {
  if (target.includes("/")) target = target.split("/").slice(-1)[0];
  if (import.meta.env.VITE_PHP_BASE_PATH) return import.meta.env.VITE_PHP_BASE_PATH + target;
  return this.options.history.base.replace("/v", "/") + target;
};

router.goto = function (url) {
  if (!url) return;
  if (url.includes(".php")) {
    const goUrl = this.php_app_url(url);
    console.log("Router PHP goto asked " + url + " => " + goUrl);
    window.location.href = goUrl;
  } else {
    console.log("Router direct goto: " + url);
    window.location.href = url;
  }
};

export default router;
