<template>
  <button class="search-bar" @click="openSearch = true">
    <div>Search ...</div>

    <div><kbd class="kbd">⌘K</kbd></div>
  </button>
  <TopSearchBox v-if="openSearch" @close="openSearch = false"></TopSearchBox>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useMagicKeys, whenever } from "@vueuse/core";
import { useRoute } from "vue-router";
import TopSearchBox from "./TopSearchBox.vue";
import { serieContextStore } from "@/store/serie_context";
import { WSClient } from "@/ems/WSClient";

const route = useRoute();
const serieContext = serieContextStore();
const openSearch = ref(false);
const keys = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.ctrlKey && ["k", "K"].includes(e.key)) {
      e.preventDefault();
    }
  },
});
const ctrlK = keys["Control+K"];
whenever(ctrlK, () => {
  openSearch.value = true;
});
</script>

<style lang="css" scoped>
.search-bar {
  width: 30rem;
  height: 65%;
  margin: auto;
  border-style: solid;
  border-radius: 1rem;
  border-width: 1px;
  padding: 0.5rem;
  padding-right: 0.3rem;
  padding-left: 2rem;
  border-color: rgb(54, 145, 127);
  background-color: rgb(0, 168, 134);
  color: var(--green-light-color);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  transition-duration: 0.2s;
}
.search-bar:hover {
  background-color: rgb(30, 180, 150);
  color: white;
}
kbd {
  border-radius: 0.5rem;
  border: 1px solid rgb(255, 255, 255);
  font-size: 0.85em;
  line-height: 0.85em;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: 0.6rem 0.2rem;
  white-space: nowrap;
}
</style>
